import {Component, Input} from '@angular/core';
import {CmsItem} from '../../../model/cms-item.model';

@Component(
    {
        selector: `lib-core-cms-item`,
        template: `
            <div *ngIf="this.cmsItem" [injectHTML]="this.cmsItem.content"></div>`,
    }
)
export class CmsItemComponent {
    @Input() public cmsItem: CmsItem;
}
