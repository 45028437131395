import { Component, OnInit, Input } from '@angular/core';
import {CoreApiService} from '../../services/core-api.service';
import {MenuItem} from '../../interfaces/menu.interface';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AuthenticationState} from '../../stores/auth/auth.model';
import {MenuService} from '../../services/menu.service';
import {AuthService} from '../../services/auth.service';
import { Router, NavigationStart } from '@angular/router';
import {ScopeEnum} from '../../enums/scope.enum';
import {CoreUser} from '../../interfaces/core-user.interface';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() allMenuItems: MenuItem[] = [];
  @Input() showSettings = false;
  @Input() scope: string;

  public user: CoreUser;
  public menuItems: MenuItem[];
  public currentLang;
  public languages = ['nl', 'fr'];
  public isMenuOpen = false;
  public isCustomerPlatform = false;

  public constructor(
      public translateService: TranslateService,
      public store: Store<{ auth: AuthenticationState }>,
      private apiService: CoreApiService,
      private menuService: MenuService,
      public router: Router
  ) {
  }

  public ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.isCustomerPlatform = this.scope === ScopeEnum.PLATFORM_CUSTOMERS;

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
    this.store.select('auth').subscribe((state: AuthenticationState) => {
      this.user = state.user;

      this.menuItems = this.menuService.getAuthorizedMenuItems(this.allMenuItems);
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.isMenuOpen = false;
      }
    });
  }

  /**
   * Switch language
   */
  public switchLanguage(lang: string): void {
    this.translateService.use(lang).subscribe(response => {
      this.currentLang = this.translateService.currentLang;
    });
  }

  public logout(): void {
    this.apiService.logout().subscribe();
  }

  /**
   * Responsive menu
   */
  public toggleMainMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public showMenuItem(menuItem: MenuItem) {
    return (!menuItem.hideInLanguages ||
      (menuItem.hideInLanguages && !menuItem.hideInLanguages.includes(this.translateService.currentLang)));
  }
}
