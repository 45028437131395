import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {catchError, finalize, tap} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import {AuthenticationState} from '../stores/auth/auth.model';
import {ThrowAction} from '../stores/error/error.actions';
import {getCookie} from '../factories/get-cookie.factory';
import {RequestOptions} from '../interfaces/request-options.interface';
import {EnvService} from './env.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from "./loader.service";

@Injectable()
export class ApiHttpClientService {

    private baseUrl = '/api';
    private timerSpinner: any;
    private countSpinners = 0;

    // Extending the HttpClient through the Angular DI.
    public constructor(
        protected http: HttpClient,
        protected store: Store<{ auth: AuthenticationState }>,
        protected env: EnvService,
        protected translateService: TranslateService,
        protected loaderService: LoaderService
    ) {
    }

    public getErrorCode(err: any): string {
        if (err.error) {
            if (err.error.error) {
                if (err.error.error.code) {
                    return String(err.error.error.code);
                }
            }
        }

        return '';
    }

    /**
     * Dispatch a throw action (this usually shows an alert dialog) based on
     * extended RequestOptions
     */
    public catchError = (options?: RequestOptions) => {
        const dispatchError = (options !== undefined)
            ? (options.dispatchError !== undefined ? options.dispatchError : true)
            : true;

        return (err: HttpErrorResponse) => {
            if (dispatchError) {
                this.store.dispatch(new ThrowAction({
                    code: this.getErrorCode(err),
                    message: err.message,
                    originalError: err
                }));
            }

            return throwError(err);
        };
    }

    public get<T>(endPoint: string, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options };
        options = this.appendCSRFTokenHeader(options);
        options = this.appendSyntecAppIdHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.get<T>(this.baseUrl + endPoint, options).pipe(
            tap(this.spin(options)),
            finalize(() => this.hideSpinner()),
            catchError(this.catchError(options))
        );
    }

    public post<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options };
        options = this.appendCSRFTokenHeader(options);
        options = this.appendSyntecAppIdHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.post<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            finalize(() => this.hideSpinner()),
            catchError(this.catchError(options))
        );
    }

    public put<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options };
        options = this.appendCSRFTokenHeader(options);
        options = this.appendSyntecAppIdHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.put<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            finalize(() => this.hideSpinner()),
            catchError(this.catchError(options))
        );
    }

    public patch<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options };
        options = this.appendCSRFTokenHeader(options);
        options = this.appendSyntecAppIdHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.patch<T>(this.baseUrl + endPoint, params, options).pipe(
            tap(this.spin(options)),
            finalize(() => this.hideSpinner()),
            catchError(this.catchError(options))
        );
    }

    public delete<T>(endPoint: string, options?: RequestOptions): Observable<T> {
        options = { withCredentials: true, ...options };
        options = this.appendCSRFTokenHeader(options);
        options = this.appendSyntecAppIdHeader(options);
        options = this.appendLanguageHeader(options);

        return this.http.delete<T>(this.baseUrl + endPoint, options).pipe(
            tap(this.spin(options)),
            finalize(() => this.hideSpinner()),
            catchError(this.catchError(options))
        );
    }

    /**
     * Adds the authorization header if applicable
     */
    private appendSyntecAppIdHeader(options?: RequestOptions): RequestOptions {
        if (this.env.syntecAppId) {
            return this.appendHeader(options, 'X-Syntec-App-ID', this.env.syntecAppId);
        }

        return options;
    }

    private appendCSRFTokenHeader(options?: RequestOptions): RequestOptions {
        const csrfToken = getCookie('csrfcookie');
        return this.appendHeader(options, 'X-CSRF-Token', csrfToken);
    }

    /**
     * Adds a language header if applicable
     */
    private appendLanguageHeader(options?: RequestOptions): RequestOptions {
        if (this.translateService.currentLang) {
            return this.appendHeader(options, 'X-Language', this.translateService.currentLang);
        }

        return options;
    }

  /**
   * @param options
   * @private
   */
    private appendNoLoaderHeader(options?: RequestOptions): RequestOptions {
      return this.appendHeader(options, 'X-No-Loader', 'true');
    }

    /**
     * Appends a header to RequestOptions, when null is passed as argument
     * a new RequestOptions object will be created
     */
    public appendHeader(options: RequestOptions | null, headerKey: string, headerValue: string): RequestOptions {
        if (!options) {
            options = {
                headers: new HttpHeaders()
            };
        }

        if (!options.headers) {
            options.headers = new HttpHeaders();
        }

        options.headers = options.headers.append(headerKey, headerValue);

        return options;
    }

    public getBaseUrl(): string
    {
        return this.baseUrl;
    }

    public getHttpClient(): HttpClient
    {
        return this.http;
    }


  private hideSpinner = () => {
    this.countSpinners--;

    if (this.timerSpinner) {
      clearTimeout(this.timerSpinner);
    }
    this.loaderService.hide();
  }

  /**
   * Conditionally show a loading spinner based on extended requestOptions
   */
  private spin = (options?: RequestOptions) => {
    this.countSpinners++;

    let shouldSpin = true;
    let spinTimeout = 500;
    if (options) {
      if (options.spinner !== undefined) {
        shouldSpin = options.spinner;
      }
      if (options.spinnerWait !== undefined) {
        spinTimeout = options.spinnerWait;
      }
    }

    this.timerSpinner = shouldSpin ? setTimeout(() => {
      // Only show the spinner if a spinner is still running
      if (this.countSpinners > 0) {
        this.loaderService.show();
      }
    }, spinTimeout) : null;

    return () => {
    };
  }
}
