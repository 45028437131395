import {Injectable} from '@angular/core';
import {MessageService} from './message.service';

@Injectable()
export class UtilService {
    constructor(
        private messageService: MessageService,
    ) {
    }

    /**
     * Refresh view
     * @param viewGuid
     */
    refreshView(viewGuid: string) {
        this.messageService.sendMessage({action: 'view-submit', params: viewGuid});
    }

    isNumeric(value: any): boolean {
      return (!isNaN(parseFloat(value)) && isFinite(value));
    }
}
