import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CmsItem} from '../../../model/cms-item.model';

@Component(
    {
        template: `
            <lib-core-cms-item [cmsItem]="this.cmsItem"></lib-core-cms-item>`,
    }
)
export class NewsItemComponent implements OnInit {
    @Input() public cmsItem: CmsItem;

    public constructor(
        private route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data: any): void => {
            this.cmsItem = data.cmsItem;
        });
    }
}
