import {Component, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {TimelineAction} from "../../../model/timeline-action.model";
import {OrderByPipe} from "ngx-pipes";

@Component({
  selector: 'core-timeline-list',
  templateUrl: './timeline-list.component.html',
  styleUrls: ['./timeline-list.component.scss'],
  providers: [OrderByPipe]
})
export class TimelineListComponent implements OnChanges {

  public sortedTimelineActions: TimelineAction[] = [];

  public selectedTimelineAction: TimelineAction | null = null;

  /**
   * Active timeline action guid
   */
  @Input() public activeTimelineActionGuid: string = null;

  /**
   * Indicates if a timeline action needs to be selected by default or provided via activeTimelineActionGuid
   */
  @Input() public selectDefaultTimelineAction = false;

  /**
   * Timeline actions
   */
  @Input() public timelineActions: TimelineAction[] = [];

  /**
   * Event emitter when selecting action
   */
  @Output() selectActionEvent = new EventEmitter<TimelineAction>();

  /**
   * Constructor
   */
  constructor(private orderByPipe: OrderByPipe) {
  }

  /**
   * Sort timeline actions and set one action as selected when they have changed
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.timelineActions) {
      return;
    }

    // Sort timeline actions by status and date descending
    this.sortedTimelineActions = this.orderByPipe.transform(changes.timelineActions.currentValue, ['statusSorter', '-date']);

    if (this.selectDefaultTimelineAction) {
      let timelineAction: TimelineAction | null = null;

      if (this.sortedTimelineActions.length > 0) {
        let filteredActions: TimelineAction[] = [];
        if (this.activeTimelineActionGuid) {
          filteredActions = this.timelineActions.filter(action => action.guid === this.activeTimelineActionGuid);
        }

        // Set active action for provided action guid if available, otherwise from first in list
        timelineAction = filteredActions.length > 0 ? filteredActions[0] : this.sortedTimelineActions[0];

        // Scroll to selected timeline action (in case not visible because of overflow)
        setTimeout(() => {
          const element = document.querySelector('.timeline  .item.active');
          if (element) {
            element.scrollIntoView({
              block: 'nearest',
              inline: 'start'
            });
          }
        }, 0);

      } else {
        timelineAction = null;
      }

      this.selectAction(timelineAction);
    }
  }

  /**
   * Check if a timeline action is expired
   */
  public isExpired(date?: string): boolean {
    if (date === null) {
      return false;
    }

    return new Date() > new Date(date);
  }

  public getStatus(timelineAction: TimelineAction): string {
    if (timelineAction.statusCid === 'closed') {
      return timelineAction.statusCid;
    }

    if (this.isExpired(timelineAction.date)) {
      return 'expired';
    }

    return timelineAction.statusCid;
  }

  /**
   * Select action
   */
  public selectAction(timelineAction: TimelineAction | null): void {
    this.selectedTimelineAction = timelineAction;

    if (timelineAction) {
      this.selectActionEvent.emit(timelineAction);
    }
  }
}
