<div class="modal-body-scroll">
  <div class="modal-header" *ngIf="title">
    <h5 class="modal-title">{{title}}</h5>
  </div>
  <div class="modal-body" (scroll)="onScroll($event)" #modalBody>
    <!-- Dirty hack to make sure the focus is on the first (hidden) element of the body. Otherwise Angular bootstrap modal
    will scoll to first focusable element in the content -->
    <input type="text" style="display:none" />
    <p class="cmb" [innerHTML]="body | safeHtml"></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="dismiss()" *ngIf="type === 'confirm'">{{btnCancel}}</button>
    <button type="button" class="btn btn-primary" (click)="close()" [disabled]="isButtonDisabled">{{btnClose}}</button>
  </div>
</div>

