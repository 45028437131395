import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationState} from '../../stores/auth/auth.model';
import {Store} from '@ngrx/store';
import {MenuItem} from '../../interfaces/menu.interface';
import {MENU_ITEMS} from '../../../../../customer/src/app/shared/constants/menu.constant';
import {ScopeEnum} from '../../enums/scope.enum';

@Component({
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() scope: string;

  public currentDate = new Date();
  public menuItems: MenuItem[] = [];
  public isLoggedIn = false;

  constructor(
    public store: Store<{ auth: AuthenticationState }>,
  ) { }

  ngOnInit(): void {
    this.menuItems = MENU_ITEMS;
    this.store.select('auth').subscribe((auth: AuthenticationState) => {
      this.isLoggedIn = auth.user ? true : false;
    });
  }
}
