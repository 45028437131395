import { Action } from '@ngrx/store';
import {CoreUser} from '../../interfaces/core-user.interface';


const KEY = 'AUTH';
export const LOGGED_IN = `[${KEY}] LoggedIn`;
export const LOGGED_OUT = `[${KEY}] LoggedOut`;
export const UNAUTHORIZED = `[${KEY}] Unauthorized`;

export class LoggedInAction implements Action {
    public readonly type = LOGGED_IN;
    constructor(public payload: CoreUser) { }
}

export class LoggedOutAction implements Action {
    public readonly type = LOGGED_OUT;
    constructor(public payload = null) { }
}

export class UnauthorizedAction implements Action {
    public readonly type = UNAUTHORIZED;
    constructor(public payload = null) { }
}

export type AuthenticationActions = LoggedInAction | LoggedOutAction | UnauthorizedAction;

