import { Injectable } from "@angular/core";
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoaderService {
  public loader = new BehaviorSubject<boolean>(false);

  /**
   * Show loader
   */
  public show() {
    this.loader.next(true);
  }

  /**
   * Hide loader
   */
  public hide() {
    this.loader.next(false);
  }
}
