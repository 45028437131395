import {Component, OnInit, Input, ViewContainerRef, ComponentFactoryResolver} from "@angular/core";
import {ViewComponent} from './view.component';
import {TableBodyRowCell} from '../../interfaces/view/table-body-row-cell.model';
import {TableBodyRow} from '../../interfaces/view/table-body-row.model';
import {CellDynamicComponent} from "../../interfaces/view/cell-dynamic-component.model";

@Component({
  selector: 'view-cell-component',
  template: '',
})
export class ViewCellComponent implements OnInit {
  @Input() public component: any;
  @Input() public cell: TableBodyRowCell;
  @Input() public row: TableBodyRow;
  @Input() public viewComponent: ViewComponent;
  @Input() public values: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public ngOnInit() {
    const factoryClass = this.component;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<CellDynamicComponent>(
      factoryClass
    );
    const componentRef = this.viewContainerRef.createComponent<CellDynamicComponent>(
      componentFactory
    );
    componentRef.instance.cell = this.cell;
    componentRef.instance.row = this.row;
    componentRef.instance.viewComponent = this.viewComponent;
    componentRef.instance.values = this.values;

    componentRef.changeDetectorRef.detectChanges();
  }
}
