import {Component, OnInit, Input, HostListener, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'core-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, AfterViewInit {
  @Input() public title: string;
  @Input() public body: string;
  @Input() public btnClose: string;
  @Input() public btnCancel: string;
  @Input() public type: string;
  @HostListener('scroll', ['$event'])
  @ViewChild('modalBody') modalBody: ElementRef;

  public isButtonDisabled: boolean = false;

  constructor(
      private activeModal: NgbActiveModal,
      private translateService: TranslateService
  ) {}

  public ngOnInit() {
    if (this.title) {
      this.title = this.translateService.instant(this.title);
    }

    if (this.body) {
      this.body = this.translateService.instant(this.body);
    }

    this.btnClose = this.translateService.instant(this.btnClose ? this.btnClose : 'global.close');
    this.btnCancel = this.translateService.instant(this.btnCancel ? this.btnCancel : 'global.cancel');
  }

  /**
   * Check if buttons needs to be disabled
   */
  ngAfterViewInit() {
    this.checkButtonDisabled(this.modalBody.nativeElement, true);
  }

  /**
   * Close modal
   */
  public close() {
    this.activeModal.close();
  }

  /**
   * Dismiss modal
   */
  public dismiss() {
    this.activeModal.dismiss();
  }

  /**
   * Check button status when scrolling
   * @param event
   */
  public onScroll(event: any) {
    this.checkButtonDisabled(event.target);
  }

  /**
   * Check if buttons needs to be disabled
   * @param element
   * @param initialize
   * @private
   */
  private checkButtonDisabled(element: any, initialize: boolean = false) {
    if (this.type === 'mandatory') {
      if (initialize) {
        this.isButtonDisabled = true;
      }

      if (this.isButtonDisabled && element.offsetHeight + element.scrollTop >= element.scrollHeight) {
        this.isButtonDisabled = false;
      }
    }
  }
}
