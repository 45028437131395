<div class="timeline timeline side timeline-side">
  <div>
    <div class="wrapper">
      <div class="items">
        <div class="empty-list-placeholder" *ngIf="sortedTimelineActions.length === 0">
          <i class="icon fal fa-bolt"></i>
          <h3 class="title">{{ 'home.home.timeline.no-actions.title' | translate }}</h3>
          <div class="message">{{ 'home.home.timeline.no-actions.description' | translate }}</div>
        </div>
        <ng-container *ngFor="let timelineAction of sortedTimelineActions;">
          <div class="item" (click)="selectAction(timelineAction)" [class]="getStatus(timelineAction)" [class.active]="selectedTimelineAction && selectedTimelineAction.guid === timelineAction.guid">
            <div class="line">
              <div class="circle"></div>
            </div>
            <div class="details">
              <div class="date">
                <ng-container *ngIf="!timelineAction.isQuarter">{{timelineAction.date | date: 'd'}} {{ ('datepicker.month.' + ((timelineAction.date | date: 'M' | number) - 1)) | translate}}</ng-container>
                <ng-container *ngIf="timelineAction.isQuarter">{{ 'global.quarter.short' | translate }}{{timelineAction.quarterNumber}}</ng-container>
                &nbsp;{{timelineAction.date | date: 'yyyy'}}
              </div>
              <div class="title">
                <div class="text">{{timelineAction.title}}</div>
              </div>
              <div class="status">
                <div class="badge" *ngIf="getStatus(timelineAction) !== 'expired'" [class.badge-success]="getStatus(timelineAction) === 'running' || getStatus(timelineAction) === 'planned'" [class.badge-gray]="getStatus(timelineAction) === 'closed'">{{timelineAction.status}}</div>
                <div class="badge badge-warning ml-1" *ngIf="getStatus(timelineAction) === 'expired'">{{ 'timeline.timeline.expired' | translate }}</div>
              </div>
            </div>
            <div class="arrow">
              <i class="fal fa-angle-right icon"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
