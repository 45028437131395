import {Directive, ElementRef, Input} from '@angular/core';

@Directive(
    {
        selector: '[injectHTML]',
    }
)
export class InjectHTMLDirective {
    @Input() set injectHTML(html: string) {
        this.host.nativeElement.innerHTML = html;
    }

    constructor(private host: ElementRef) {
    }
}
