import {Component, Input, OnInit, Output, EventEmitter, DoCheck} from '@angular/core';
import {CoreApiService} from '../../services/core-api.service';

@Component({
  selector: 'core-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss']
})
export class PasswordValidationComponent implements DoCheck {
  @Input() public currentPassword;
  @Input() public newPassword;
  @Input() public newPasswordConfirm;
  @Output() isValidEvent = new EventEmitter<boolean>();

  public checkCurrentPassword;
  private prevCurrentPassword: string;
  private prevNewPassword: string;
  private prevNewPasswordConfirm: string;

  private isValid = false;
  public isValidCurrentPassword = false;
  public isValidLength = false;
  public isValidLowercase = false;
  public isValidUppercase = false;
  public isValidNumbers = false;
  public isValidSpecialCharacters = false;
  public isValidEqual = false;

  private timeout: any;

  constructor(
      private coreApiService: CoreApiService
  ) {
  }

  ngOnInit() {
    this.checkCurrentPassword = this.currentPassword !== undefined;
  }

  /**
   * Check if password is valid on change
   */
  ngDoCheck() {
    if (this.prevCurrentPassword !== this.currentPassword) {
      this.prevCurrentPassword = this.currentPassword;

      this.testCurrentPassword();
    }

    if (this.prevNewPassword !== this.newPassword) {
      this.prevNewPassword = this.newPassword;

      this.testForLength();
      this.testForLowercase();
      this.testForUppercase();
      this.testForNumbers();
      this.testForSpecialCharacters();
    }

    if (this.prevNewPasswordConfirm !== this.newPasswordConfirm) {
      this.prevNewPasswordConfirm = this.newPasswordConfirm;

      this.testIfEqual();
    }

    this.emitStatus();
  }

  public testCurrentPassword() {
    if (!this.checkCurrentPassword) {
      return;
    }

    let that = this;
    if (!this.currentPassword) {
      this.isValidCurrentPassword = false;
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(function () {
      that.coreApiService.checkPassword(that.currentPassword).subscribe(response => {
        that.isValidCurrentPassword = response?.valid === true;
      })
    }, 1000);
  }

  public testForLength() {
    this.isValidLength = this.newPassword && this.newPassword.length >= 8;
  }

  public testForLowercase() {
    this.isValidLowercase = this.newPassword && /[a-z]/.test(this.newPassword);
  }

  public testForUppercase() {
    this.isValidUppercase = this.newPassword && /[A-Z]/.test(this.newPassword);
  }

  public testForNumbers() {
    this.isValidNumbers = this.newPassword && /[0-9]/.test(this.newPassword);
  }

  public testForSpecialCharacters() {
    this.isValidSpecialCharacters = this.newPassword && /[^A-Za-z0-9]/.test(this.newPassword);
  }

  public testIfEqual() {
    this.isValidEqual = this.newPassword && this.newPassword === this.newPasswordConfirm;
  }

  public emitStatus() {
    this.isValid =
        (!this.checkCurrentPassword || (this.checkCurrentPassword && this.isValidCurrentPassword)) &&
        this.isValidLength &&
        this.isValidLowercase &&
        this.isValidUppercase &&
        this.isValidNumbers &&
        this.isValidSpecialCharacters &&
        this.isValidEqual;

    this.isValidEvent.emit(this.isValid);
  }
}
