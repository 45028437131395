import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Directive} from '@angular/core';
import {Observable} from 'rxjs';
import {CoreApiService} from '../services/core-api.service';
import {map} from 'rxjs/operators';

@Directive({
    selector: '[passwordValidator]',
    providers: [
        {provide: NG_ASYNC_VALIDATORS, useExisting: PasswordValidator, multi: true}
    ]
})
export class PasswordValidator implements AsyncValidator {

    constructor(
        private coreApiService: CoreApiService
    ) {
    }

    validate(control: AbstractControl) : Observable<ValidationErrors | null> {
        const obs = this.coreApiService.checkPassword(control.value)
            .pipe(
                map((response) => {
                    // null no error, object for error
                    return response.valid ? null : {
                        password: false
                    };
                })
            );
        return obs;
    }
}
