import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DriveLinks} from '../shared/interfaces/drive-links.interface';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public driveLinks: DriveLinks;
  public currentLang: string;

  constructor(
      private route: ActivatedRoute,
      private translateService: TranslateService
  ) { }

  public ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    
    this.route.data.subscribe((data) => {
      this.driveLinks = data.links;
    });

    // Track language changes
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

}
