import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../model/message';

@Injectable()
export class MessageService {
  private subject = new Subject<Message>();

  public data = this.subject.asObservable();

  sendMessage(message: Message) {
    this.subject.next(message);
  }
}
