import {MenuItem} from '../../../../../core/src/lib/interfaces/menu.interface';

export const MENU_ITEMS: MenuItem[] = [
    {
        title: 'menu.profit-prevention.title',
        description: 'menu.profit-prevention.description',
        icon: 'fas fa-comment-alt-lines',
        role: 'ROLE_AUTHENTICATED',
        route: '/profit_prevention'
    },
    {
        title: 'menu.risk-scan.title',
        description: 'menu.risk-scan.description',
        icon: 'fas fa-comment-alt-lines',
        role: 'ROLE_AUTHENTICATED',
        route: '/risk_scan'
    },
    {
        title: 'menu.services.title',
        description: 'menu.services.description',
        icon: 'fas fa-comment-alt-lines',
        role: 'ROLE_AUTHENTICATED',
        route: null,
        submenuItems: [
            {
                title: 'menu.services-free.title',
                description: 'menu.services-free.description',
                icon: 'fas fa-comment-alt-lines',
                role: 'ROLE_AUTHENTICATED',
                route: '/services',
            },
            {
                title: 'menu.services-paid.title',
                description: 'menu.services-paid.description',
                icon: 'fas fa-comment-alt-lines',
                role: 'ROLE_AUTHENTICATED',
                route: '/services_paid',
                hideInLanguages: []
            }
        ]
    },
    {
        title: 'menu.timeline.title',
        description: 'menu.timeline.description',
        icon: 'fas fa-comment-alt-lines',
        role: 'ROLE_AUTHENTICATED',
        route: '/timeline',
        hideFromFooter: true
    },
    {
        title: 'menu.documents.title',
        description: 'menu.documents.description',
        icon: 'fas fa-comment-alt-lines',
        role: 'ROLE_AUTHENTICATED',
        route: '/documents'
    }
];
