import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRouteSnapshot} from '@angular/router';
import {CmsItem} from '../model/cms-item.model';
import {CoreApiService} from '../services/core-api.service';

@Injectable()
export class CmsItemResolver {

    constructor(
        private apiService: CoreApiService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<CmsItem> {
        const slug = route.params[`slug`];
        if (!slug) {
            return EMPTY;
        }

        return this
            .apiService
            .getCmsItem(
                slug,
                route.data[`isNewsItem`] ?? false,
                route.data[`isPage`] ?? false,
                route.queryParams[`token`] ?? null,
                true
            );
    }
}
