<header class="c-header" [class.main-menu-open]="isMenuOpen">
  <div class="topbar">
    <div class="container">
        <ng-container *ngIf="isCustomerPlatform && user && user?.attributes?.brokers">
            <div class="broker-name" *ngFor="let broker of user?.attributes?.brokers; let last = last">
                <div class="text">
                    <i class="fal fa-handshake"></i>{{broker.name}}
                </div>
                <ng-container *ngIf="!last"><span>|</span></ng-container>
            </div>
        </ng-container>
      <div class="right">
        <ng-container *ngIf="user">
          <ul class="top-bar-list">

            <ng-container *ngIf="isCustomerPlatform">
              <li *ngIf="user?.attributes?.canViewInsuranceDocuments" class="hide-on-mobile">
                <a [routerLink]="'/insurance_documents'">{{ 'menu.insurance_documents.title' | translate }}</a>
              </li>
              <li class="hide-on-mobile">
                <a [routerLink]="'/insurances'">{{ 'menu.insurances.title' | translate }}</a>
              </li>
              <li class="hide-on-mobile">
                <a [routerLink]="'/requests'">{{ 'menu.requests.title' | translate }}</a>
              </li>
            </ng-container>
            <li *ngIf="showSettings" class="hide-on-mobile">
              <a [routerLink]="'/user/settings'">{{ 'app.settings' | translate }}&nbsp;</a>
            </li>

            <li class="user-dropdown">
              <div class="dropdown" ngbDropdown>
                <button class="btn btn-user dropdown-toggle" ngbDropdownToggle>
                  {{user.name}}
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>

                  <a class="dropdown-item" [routerLink]="'/user'" ngbDropdownItem>{{ 'app.my_profile' | translate }}&nbsp;</a>

                  <a class="dropdown-item show-on-mobile" *ngIf="user?.attributes?.canViewInsuranceDocuments && isCustomerPlatform" [routerLink]="'/insurance_documents'">{{ 'menu.insurance_documents.title' | translate }}</a>
                  <a class="dropdown-item show-on-mobile" *ngIf="isCustomerPlatform" [routerLink]="'/insurances'">{{ 'menu.insurances.title' | translate }}</a>
                  <a class="dropdown-item show-on-mobile" *ngIf="isCustomerPlatform" [routerLink]="'/requests'">{{ 'menu.requests.title' | translate }}</a>
                  <a class="dropdown-item show-on-mobile" *ngIf="showSettings"[routerLink]="'/user/settings'">{{ 'app.settings' | translate }}&nbsp;</a>

                  <a class="dropdown-item" [routerLink]="'/users'" ngbDropdownItem *ngIf="isCustomerPlatform">{{ 'menu.users.title' | translate }}&nbsp;</a>
                  <a class="dropdown-item" [routerLink]="'/user/change_password'" ngbDropdownItem>{{ 'app.change_password' | translate }}&nbsp;</a>
                  <button class="dropdown-item" (click)="logout()" ngbDropdownItem>{{ 'app.logout' | translate }}&nbsp;</button>

                </div>
              </div>
            </li>
          </ul>
        </ng-container>

        <div class="language" *ngIf="!user">
          <ng-container *ngFor="let language of languages">
            <button (click)="switchLanguage(language)" class="btn"
                    [ngClass]="{'btn-primary': currentLang === language, 'btn-light': currentLang !== language}">{{language | uppercase}}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user">
    <div class="wrapper">
      <div class="logo">
        <a [routerLink]="'/start'">
          <svg class="svg--brand-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.942 24.846">
            <g data-name="Group 50" fill="#4fc15d" fill-rule="evenodd">
              <path data-name="Path 38"
                    d="M3.953 0L0 10.212h10.671L5.087 24.846H23.2l-5.583-14.634h10.67L24.335 0z"></path>
              <path data-name="Path 36" d="M45.872 0h9.938l-9.535 24.846h-9.941L26.834 0h9.938l4.565 11.895z"></path>
              <path data-name="Path 37"
                    d="M49.257 24.846h9.938l4.6-11.988 4.6 11.988h7.454l4.565-11.9 4.565 11.9h9.969L85.377 0h-8.7l-4.565 11.895L67.519 0h-8.7z"></path>
            </g>
          </svg>
        </a>
      </div>
      <nav class="mega-menu">
        <div class="container">
          <ul>
            <ng-container *ngFor="let menuItem of menuItems">
              <li *ngIf="showMenuItem(menuItem)" [class.has-sub-menu]="menuItem.submenuItems">
                <a [routerLink]="menuItem.route">{{menuItem.title | translate}}</a>
                <ul class="sub-menu" *ngIf="menuItem.submenuItems">
                  <ng-container *ngFor="let submenuItem of menuItem.submenuItems">
                    <li *ngIf="showMenuItem(submenuItem)">
                      <a [routerLink]="submenuItem.route">{{submenuItem.title | translate}}</a>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>
      <a href="https://tvmsolutions.be/site/login" target="_blank" class="btn btn-primary btn-solutions" *ngIf="isCustomerPlatform">
          My TVM solutions
      </a>
      <div class="btn-main-menu-toggle" (click)="toggleMainMenu()">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

</header>

<!-- Modal: TVM Update

<div role="dialog" tabindex="-1" aria-modal="true" class="d-block modal show">
  <div role="document" class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-body">
          <div class="breadcrumbs">
            <ul>
              <li>
                <a href="/">Overzicht</a>
              </li>
              <li class="current">
                <a>Huidige update</a>
              </li>
            </ul>
          </div>
          <h2 class="text-primary cmb">Drive update #24</h2>
          <div class="mb-sm">
            <small class="o4">5 dagen geleden</small>
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid autem consectetur culpa debitis eligendi error nam quaerat, quod ratione? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliquid autem consectetur culpa debitis eligendi error nam quaerat, quod ratione?</p>
          <img src="../../../assets/images/truck-preventie.jpg" class="w-100 img-thumbnail" alt="_title_">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light">Sluiten</button>
          <button type="button" class="btn btn-primary">Bekijk update</button>
        </div>
      </div>
    </div>
  </div>
</div>
-->


<!-- Modal: TVM Updates overzicht

<div role="dialog" tabindex="-1" aria-modal="true" class="d-block modal show">
  <div role="document" class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-body">
          <h2 class="text-primary mb-sm">Drive updates</h2>
          <p class="cmb">Een overzicht van alle nieuwe functionaliteiten binnen de TVM Drive.</p>
        </div>
        <div class="modal-links">
          <div class="items">
            <button type="button" class="btn item">
              <div class="text">
                <div class="d-flex align-items-center">
                  <h6 class="cmb">Update 45</h6>
                  <div class="badge badge-success badge-sm ml-xs">Nieuw</div>
                </div>
                <small class="o4">5 dagen geleden</small>
              </div>
              <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.936 15.584"><path fill="currentColor" d="M0 6.629h18.145v2.326H0z"></path><path fill="currentColor" d="M13.145 15.584l-1.629-1.629 6.168-6.163-6.164-6.163L13.149 0l7.791 7.792z"></path></svg>
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light">Sluiten</button>
          <button type="button" class="btn btn-primary">Bekijk update</button>
        </div>
      </div>
    </div>
  </div>
</div>

-->
