import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {TimelineAction} from "../../model/timeline-action.model";
import {CoreUser} from "../../model/user.model";
import {CoreApiService} from "../../services/core-api.service";
import {AlertService} from "../../services/alert.service";
import {ToastService} from "../../services/toast.service";
import {AuthenticationState} from "../../stores/auth/auth.model";
import {Record} from "../../interfaces/record.interface";
import {TimelineActionComponent} from "./timeline-action/timeline-action.component";
import {RecordResponse} from "../../interfaces/record-response.interface";
import {StatusEnum} from "../../enums/status.enum";
import {HttpParams} from "@angular/common/http";
import {TimelineActionFileDownload} from '../../model/timeline-action-file-download.model';

@Component({
  selector: 'core-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {

  /**
   * Form guid to manage timeline actions
   */
  @Input() public formGuidTimelineAction: string;

  /**
   * Timeline action which is opened by default
   */
  @Input() public defaultGuidTimelineAction: string | null = null;

  /**
   * List of timeline actions
   */
  @Input() public timelineActions: TimelineAction[];

  /**
   * Customer guid
   */
  @Input() public customerGuid: string;

    /**
     * Refresh timeline actions
     */
    @Output() refreshTimelineActions = new EventEmitter<TimelineAction[]>();

    /**
     * Download timeline action file
     */
    @Output() downloadTimelineActionFile = new EventEmitter<TimelineActionFileDownload>();

  /**
   * Active timeline action
   */
  public activeTimelineAction: TimelineAction;

  /**
   * Auhtenticated user
   */
  public authUser: CoreUser;

  /**
   * Indicates if initial data is loaded
   */
  public initialLoading = true;

  /**
   * Constructor
   * @param modalService
   * @param coreApiService
   * @param alertService
   * @param toastService
   * @param store
   * @param orderByPipe
   */
  constructor(
    private modalService: NgbModal,
    private coreApiService: CoreApiService,
    private alertService: AlertService,
    private toastService: ToastService,
    public store: Store<{ auth: AuthenticationState }>
  ) {
  }

  /**
   * On init
   */
  public ngOnInit(): void {
    this.setTimelineActions(this.defaultGuidTimelineAction);

    this.store.select('auth').subscribe((auth: AuthenticationState) => {
      this.authUser = auth.user;
    });
  }

  /**
   * Sort timeline actions and set one action as selected when they have changed
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.timelineActions && changes.timelineActions.firstChange === false) {
      // Set initial loading to false if not first change
      this.initialLoading = false;
    }
  }
  /**
   * Show new or existing action
   * @param guid
   */
  public showAction(guid?: string): void {
    // Add customer guid as query param
    let options = {};
    if (guid === undefined && this.customerGuid) {
      options = { params: new HttpParams().set('TvmPartnerCustomerTimelineAction.tvmPartnerCustomer', this.customerGuid) };
    }

    // Fetch timeline action and display in modal
    this.coreApiService.getRecord(this.formGuidTimelineAction, guid, options).subscribe((record: Record) => {
      const modalRef = this.modalService.open(TimelineActionComponent, {
        size: 'lg',
        scrollable: true,
        backdrop: 'static',
        animation: false
      });
      modalRef.componentInstance.record = record;

      modalRef.componentInstance.downloadTimelineActionFile.subscribe((downloadTimelineActionFile: TimelineActionFileDownload) => {
          this.downloadTimelineActionFile.emit(downloadTimelineActionFile);
      });

      modalRef.result.then(
        (model) => {
          // Save action with new data
          this.coreApiService.saveRecord(this.formGuidTimelineAction, model, model['TvmPartnerCustomerTimelineAction.guid']).subscribe((response: RecordResponse) => {
            this.toastService.success('timeline.action.success');

            // Refresh timeline when action has been saved
            this.setTimelineActions(response.recordGuid);
          });
        },
        (dismiss) => {}
      );
    });
  }

  /**
   * Delete action
   * @param guid
   */
  public deleteAction(guid?: string): void {
    this.alertService.confirm('timeline.delete.body', {
      btnClose: 'global.delete',
      btnCancel: 'global.cancel',
      title: 'timeline.delete.title',
      size: 'lg',
      close: () => {
        // Set record status to inactive
        const data = {'TvmPartnerCustomerTimelineAction.coreStatus': StatusEnum.INACTIVE};
        this.coreApiService.saveRecord(this.formGuidTimelineAction, data, guid).subscribe((response: RecordResponse) => {
          this.toastService.success('timeline.delete.success');

          // Reload actions list
          this.setTimelineActions();
        });
      }
    });
  }

    /**
     * Download timeline action file
     * @param timelineActionGuid
     * @param timelineActionFileGuid
     */
  public downloadFile(timelineActionGuid: string, timelineActionFileGuid: string): void {
    this.downloadTimelineActionFile.emit({timelineActionGuid, timelineActionFileGuid});
  }

  /**
   * Check if current user is authorized to edit timeline action
   * @param timelineAction
   */
  public canEdit(timelineAction: TimelineAction): boolean {
    return (timelineAction.createdByCoreUser.guid === this.authUser.guid);
  }

  /**
   * Set timeline action
   */
  public setTimelineAction(timelineAction: TimelineAction|null): void {
    this.activeTimelineAction = timelineAction;
  }

  /**
   * Set timeline actions
   * @param timelineActionGuid
   * @private
   */
  private setTimelineActions(timelineActionGuid?: string): void {
    // Refresh timeline actions
    this.refreshTimelineActions.emit();

    // Set timeline action to be opened
    this.defaultGuidTimelineAction = timelineActionGuid;
  }
}
