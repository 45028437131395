<form class="large-form" (ngSubmit)="submit()" #form="ngForm" novalidate>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{'timeline.action.title' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-lg-12">

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{formFields['TvmPartnerCustomerTimelineAction.tvmCustomerRiskScanDomain']?.tr[lng]}} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <select name="type" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.tvmCustomerRiskScanDomain']" required class="form-control" #type="ngModel">
                  <option [ngValue]="null">-- {{ formFields['TvmPartnerCustomerTimelineAction.tvmCustomerRiskScanDomain']?.p[lng] }} --</option>
                  <option *ngFor="let type of formFields['TvmPartnerCustomerTimelineAction.tvmCustomerRiskScanDomain'].opt" [value]="type.id">{{type.name}}</option>
                </select>
                <core-field-validation
                        [isVisible]="type.dirty && type.invalid && type.errors.required"
                        message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.tvmCustomerRiskScanDomain']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ formFields['TvmPartnerCustomerTimelineAction.title']?.tr[lng] }} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <input type="text" name="title" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.title']" required class="form-control" #title="ngModel">
                <core-field-validation
                        [isVisible]="title.dirty && title.invalid && title.errors.required"
                        message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.title']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row" *ngIf="!record.model['TvmPartnerCustomerTimelineAction.guid'] || record.model['TvmPartnerCustomerTimelineAction.coreUser']">
              <label class="col-sm-4 control-label">{{formFields['TvmPartnerCustomerTimelineAction.coreUser']?.tr[lng]}} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <select name="user" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.coreUser']" required class="form-control" #user="ngModel">
                  <option [ngValue]="null">-- {{ formFields['TvmPartnerCustomerTimelineAction.coreUser']?.p[lng] }} --</option>
                  <option *ngFor="let user of formFields['TvmPartnerCustomerTimelineAction.coreUser'].opt" [value]="user.id">{{user.name}}</option>
                </select>
                <core-field-validation
                        [isVisible]="user.dirty && user.invalid && user.errors.required"
                        message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.coreUser']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{formFields['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus']?.tr[lng]}} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <div class="btn-group btn-group-toggle" ngbRadioGroup data-toggle="buttons" name="status" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus']" #status="ngModel">
                  <label ngbButtonLabel class="btn-light" *ngFor="let status of formFields['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus'].opt">
                    <input ngbButton type="radio" [value]="status.id"> {{status.name}}
                  </label>
                  <core-field-validation
                          [isVisible]="status.dirty && status.invalid && status.errors.required"
                          message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus']?.tr[lng]} }}">
                  </core-field-validation>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ formFields['TvmPartnerCustomerTimelineAction.isQuarter']?.tr[lng] }} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.isQuarter']">
                  <label ngbButtonLabel class="btn-light">
                    <input ngbButton type="radio" [value]="false"> {{formFields['TvmPartnerCustomerTimelineAction.date']?.tr[this.lng]}}
                  </label>
                  <label ngbButtonLabel class="btn-light">
                    <input ngbButton type="radio" [value]="true"> {{formFields['TvmPartnerCustomerTimelineAction.quarterNumber']?.tr[lng]}}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="!record.model['TvmPartnerCustomerTimelineAction.isQuarter']">
              <label class="col-sm-4 control-label">{{formFields['TvmPartnerCustomerTimelineAction.date']?.tr[this.lng]}} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <div class="input-group">
                  <input class="form-control"
                         name="date"
                         placeholder="{{'global.date_format' | translate}}"
                         [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.date']"
                         ngbDatepicker
                         #d="ngbDatepicker"
                         #date="ngModel"
                         [required]="!record.model['TvmPartnerCustomerTimelineAction.isQuarter']">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary fas fa-calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
                <core-field-validation
                        [isVisible]="date.dirty && date.invalid"
                        message="{{'global.validation.invalid_date' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.date']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row" *ngIf="record.model['TvmPartnerCustomerTimelineAction.isQuarter']">
              <label class="col-sm-4 control-label">{{formFields['TvmPartnerCustomerTimelineAction.quarterNumber']?.tr[lng]}} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-6">
                    <select
                        name="quarterNumber"
                        [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.quarterNumber']"
                        [required]="record.model['TvmPartnerCustomerTimelineAction.isQuarter']"
                        class="form-control"
                        #quarterNumber="ngModel">
                      <option [ngValue]="null">-- {{ formFields['TvmPartnerCustomerTimelineAction.quarterNumber']?.p[lng] }} --</option>
                      <option *ngFor="let quarterNumber of formFields['TvmPartnerCustomerTimelineAction.quarterNumber'].opt" [value]="quarterNumber.id">{{ 'global.quarter.short' | translate }}{{quarterNumber.name}}</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <select
                            name="quarterYear"
                            [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.quarterYear']"
                            [required]="record.model['TvmPartnerCustomerTimelineAction.isQuarter']"
                            class="form-control"
                            #quarterYear="ngModel">
                      <option [ngValue]="null">-- {{ formFields['TvmPartnerCustomerTimelineAction.quarterYear']?.p[lng] }} --</option>
                      <option *ngFor="let counter of [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]" [value]="currentYear + counter">{{currentYear + counter}}</option>
                    </select>
                  </div>
                </div>
                <core-field-validation
                        [isVisible]="(quarterNumber.dirty && quarterNumber.invalid && quarterNumber.errors.required) || (quarterYear.dirty && quarterYear.invalid && quarterYear.errors.required)"
                        message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.quarterNumber']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ formFields['TvmPartnerCustomerTimelineAction.description']?.tr[lng] }} <span class="mandatory">*</span></label>
              <div class="col-md-8">
                <textarea name="description" [(ngModel)]="record.model['TvmPartnerCustomerTimelineAction.description']" required class="form-control" #description="ngModel" rows="4"></textarea>
                <core-field-validation
                  [isVisible]="description.dirty && description.invalid && description.errors.required"
                  message="{{'global.validation.required' | translate: {fieldName: formFields['TvmPartnerCustomerTimelineAction.description']?.tr[lng]} }}">
                </core-field-validation>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ formFields['TvmPartnerCustomerTimelineActionFile.coreFile']?.tr[lng] }}</label>
              <div class="col-md-8">
                <input #fileInput type="file" ng2FileSelect [uploader]="uploader" hidden />
                <div class="upload-field upload-field-lg upload-field-hover mb-md" ng2FileDrop [uploader]="uploader" (click)="fileInput.click()">
                  <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.47 23.46">
                    <polygon points="1.2 13.83 1.2 13.83 1.21 13.83 1.2 13.83"></polygon>
                    <path d="M26.41,14.54a1.1,1.1,0,0,0-.25-.43,1,1,0,0,0-.4-.29,1.24,1.24,0,0,0-.49-.11,1.17,1.17,0,0,0-.49.11,1,1,0,0,0-.41.29,1.24,1.24,0,0,0-.25.43,1.21,1.21,0,0,0-.06.5v3.61a2.39,2.39,0,0,1-.18.92,2.42,2.42,0,0,1-.52.78,2.37,2.37,0,0,1-.78.52,2.3,2.3,0,0,1-.92.19H4.81a2.23,2.23,0,0,1-.92-.19,2.42,2.42,0,0,1-.78-.52,2.27,2.27,0,0,1-.52-.78,2.3,2.3,0,0,1-.18-.93V15a1.18,1.18,0,0,0-.36-.85,1.22,1.22,0,0,0-.85-.36,1.24,1.24,0,0,0-.85.36A1.21,1.21,0,0,0,0,15v3.61a4.81,4.81,0,0,0,4.81,4.81H21.65a4.91,4.91,0,0,0,1.85-.36,5,5,0,0,0,1.56-1.05,4.79,4.79,0,0,0,1-1.56,4.7,4.7,0,0,0,.37-1.85V15A1.06,1.06,0,0,0,26.41,14.54Z"></path>
                    <path d="M7.47,6.72A1.28,1.28,0,0,0,7.88,7a1.43,1.43,0,0,0,.5.09A1.36,1.36,0,0,0,8.86,7a1.2,1.2,0,0,0,.4-.31L12,4V16a1.18,1.18,0,0,0,.09.46,1.16,1.16,0,0,0,.26.39,1.14,1.14,0,0,0,.39.27,1.25,1.25,0,0,0,.46.09h0a1.21,1.21,0,0,0,.86-.35,1.24,1.24,0,0,0,.35-.86V4l2.78,2.65a1.16,1.16,0,0,0,.39.31,1.41,1.41,0,0,0,.49.12A1.33,1.33,0,0,0,18.59,7,1.22,1.22,0,0,0,19,6.72a1.29,1.29,0,0,0,.26-.42,1.21,1.21,0,0,0,.07-.5,1.16,1.16,0,0,0-.14-.48,1.07,1.07,0,0,0-.32-.38L14.07.33a1.21,1.21,0,0,0-1.66,0L7.59,4.94a1.18,1.18,0,0,0-.32.38,1.15,1.15,0,0,0-.13.48,1.07,1.07,0,0,0,.07.5A1,1,0,0,0,7.47,6.72Z"></path>
                  </svg>
                  <div class="text">{{'timeline.action.drag-attachments' | translate}}</div>
                </div>
                <ul class="attachments" *ngIf="record.helper['TvmPartnerCustomerTimelineActionFiles'].length">
                  <ng-container *ngFor="let actionFile of record.helper['TvmPartnerCustomerTimelineActionFiles']">
                    <li class="attachment" *ngIf="actionFile.checksum">
                      <div class="name">{{actionFile.name}}</div>
                      <i class="fal fa-trash-alt btn-delete" (click)="deleteFile(actionFile.coreFileGuid)"></i>
                      <i class="fal fa-download btn-download" (click)="downloadFile(record.model['TvmPartnerCustomerTimelineAction.guid'], actionFile.guid)"></i>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="cancel()">{{ 'global.cancel' | translate }}</button>
      <button type="submit" class="btn btn-primary">{{ 'global.save' | translate }}</button>
    </div>
  </div>
</form>
