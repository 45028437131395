import {Injectable} from '@angular/core';
import {ApiAdapter} from './api.adapter';
import {CmsItem} from '../model/cms-item.model';

@Injectable()
export class CmsItemAdapter implements ApiAdapter<CmsItem> {
    public fromApi(cmsItem: {}): CmsItem {
        return {
            content: cmsItem[`content`],
        } as CmsItem;
    }
}
