import {Component, Input, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'core-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['./vimeo.component.scss']
})
export class VimeoComponent implements OnInit {

    /**
     * Vimeo video identifier NL
     */
    @Input() public videoVimeoIdentifierNl: string;

    /**
     * Vimeo video identifier FR
     */
    @Input() public videoVimeoIdentifierFr: string;

    /**
     * Vimeo video identifier
     */
    public videoVimeoIdentifier: string;

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
        // Set video identifier for language
        this.setVideoIdentifier(this.translateService.currentLang);
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setVideoIdentifier(event.lang);
        });
    }

    /**
     * Set video Vimeo identifier based on language
     * @param lang
     * @private
     */
    private setVideoIdentifier(lang: string): void {
        if (lang === 'fr') {
            this.videoVimeoIdentifier = this.videoVimeoIdentifierFr;
        } else {
            this.videoVimeoIdentifier = this.videoVimeoIdentifierNl;
        }
    }
}
