import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {AuthenticationState} from '../stores/auth/auth.model';
import {CoreUser} from '../interfaces/core-user.interface';

@Injectable()
export class AuthService {
    /**
     * User access token data
     */
    private user: CoreUser; // TODO: Take user from broker or customer!!

    constructor(
        public store: Store<{ auth: AuthenticationState }>
    ) {
        this.store.select('auth').subscribe((auth: AuthenticationState) => {
            this.user = auth.user;
        });
    }

    public hasRole(role: string): boolean {
        if (!this.isAuthenticated() || !Array.isArray(this.user.roles)) {
            return false;
        }

        return this.user.roles.indexOf(role) > -1;
    }

    public isAuthenticated(): boolean {
        return !!this.user;
    }

    public getUser(): CoreUser | null {
        return this.user ?? null;
    }
}
