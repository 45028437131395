import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CoreModule} from '../../../core/src/lib/core.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {SyntecTranslateLoader} from '../../../core/src/lib/services/translate-loader.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {StoreModule} from '@ngrx/store';
import {AuthenticationReducer} from '../../../core/src/lib/stores/auth/auth.reducer';
import {ErrorReducer} from '../../../core/src/lib/stores/error/error.reducer';
import {HttpClientModule} from '@angular/common/http';
import {LoaderService} from '../../../core/src/lib/services/loader.service';
import {MenuService} from '../../../core/src/lib/services/menu.service';
import {AuthService} from '../../../core/src/lib/services/auth.service';
import { HomeComponent } from './home/home.component';
import {DriveLinkResolver} from './shared/resolvers/drive-link.resolver';
import {ApiService} from './shared/services/api.service';
import {TranslateInitializerFactory} from '../../../core/src/lib/factories/translate-initializer.factory';
import {CoreApiService} from '../../../core/src/lib/services/core-api.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: SyntecTranslateLoader,
        deps: [Injector, 'translation-scope', 'translation-prefix']
      }
    }),
    StoreModule.forRoot({
      auth: AuthenticationReducer,
    })
  ],
  providers: [
    CoreApiService,
    LoaderService,
    MenuService,
    AuthService,
    ApiService,
    DriveLinkResolver,
    {provide: 'translation-scope', useValue: 'tvm-drive-frontend'},
    {provide: 'translation-prefix', useValue: 'tvm-drive.'},
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
