import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '../services/api.service';
import {DriveLinks} from '../interfaces/drive-links.interface';

@Injectable()
export class DriveLinkResolver implements Resolve<DriveLinks> {

  constructor(private apiService: ApiService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DriveLinks> {
    return this.apiService.getDriveLinks();
  }
}
