import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {FileUploader} from 'ng2-file-upload';
import {Record} from '../../../interfaces/record.interface';
import {FormFieldService} from '../../../services/form-field.service';
import {getCookie} from '../../../factories/get-cookie.factory';
import {AlertService} from '../../../services/alert.service';
import {TimelineActionFileDownload} from '../../../model/timeline-action-file-download.model';

@Component({
  selector: 'core-timeline-action',
  templateUrl: './timeline-action.component.html',
  styleUrls: ['./timeline-action.component.scss']
})
export class TimelineActionComponent implements OnInit  {

  @Input() public record: Record;

  @Output() downloadTimelineActionFile = new EventEmitter<TimelineActionFileDownload>();

  @ViewChild('form') public form: NgForm;

  public formFields = {};
  public lng;
  public currentYear: number;
  public uploader: FileUploader;

  constructor(
      private activeModal: NgbActiveModal,
      private formFieldService: FormFieldService,
      private translateService: TranslateService,
      private alertService: AlertService
  ) { }

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.lng = this.translateService.currentLang;
    this.formFields = this.formFieldService.getFormFieldsGrouped(this.record.formFields.c, this.record.model);

    // Set first status as default value
    if (!this.record.model['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus'] && this.formFields['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus'].opt.length > 0) {
      this.record.model['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus'] = this.formFields['TvmPartnerCustomerTimelineAction.tvmPartnerCustomerTimelineActionStatus'].opt[0].id;
    }

    // Set default year to current year
    if (!this.record.model['TvmPartnerCustomerTimelineAction.quarterYear']) {
      this.record.model['TvmPartnerCustomerTimelineAction.quarterYear'] = this.currentYear;
    }

    // Init file uploader
    this.initUploader();
  }

  /**
   * Submit action
   */
  public submit(): void {
    if (this.form.invalid) {
      this.formFieldService.markFormFieldsDirty(this.form);

      return;
    }

    // Map all files to correct model value
    this.record.model['TvmPartnerCustomerTimelineActionFile.coreFile'] = this.record.helper['TvmPartnerCustomerTimelineActionFiles'].map(actionFile => actionFile.coreFileGuid);

    this.activeModal.close(this.record.model);
  }

  /**
   * Cancel modal
   */
  public cancel(): void {
    return this.activeModal.dismiss();
  }

  /**
   * Initialize uploader
   * @private
   */
  private initUploader(): void {
    this.uploader = new FileUploader({
      url: '/api/core/file/upload',
      autoUpload: true,
      additionalParameter: {public: 0}
    });

    // Add CSRF token to request after adding file. When set in constructor it will lead to CSRF errors when other api calls are performed after page load
    const that = this;
    this.uploader.onAfterAddingFile = (item) => {
      that.uploader.setOptions({
        headers: [
          {name: 'X-CSRF-Token', value: getCookie('csrfcookie')}
        ]
      });
    };

    // Handle upload errors
    this.uploader.onErrorItem = (item, response) => {
      console.error(response);
      that.alertService.alert('error.gt400.body', {title: 'error.gt400.title'});
    };

    // Handle success uploads
    this.uploader.onSuccessItem = (item, response) => {
      const responseParsed = JSON.parse(response);
      if (responseParsed[0] !== undefined) {
        // Do not add file if it already exists
        const files = that.record.helper['TvmPartnerCustomerTimelineActionFiles'].find(x => x.coreFileGuid === responseParsed[0].guid);
        if (files) {
          return;
        }

        // Add newly uploaded file to list of files
        that.record.helper['TvmPartnerCustomerTimelineActionFiles'].push(
          {
            coreFileGuid: responseParsed[0].guid,
            checksum: responseParsed[0].checksum,
            name: responseParsed[0].name
          }
        );
      }
    };
  }

  /**
   * Delete uploaded file from list
   * @param uploadedFileGuid
   */
  public deleteFile(uploadedFileGuid: string): void {
    this.record.helper['TvmPartnerCustomerTimelineActionFiles'].splice(
      this.record.helper['TvmPartnerCustomerTimelineActionFiles'].findIndex(uploadedFile => uploadedFile['coreFileGuid'] === uploadedFileGuid),
      1
    );
  }

  /**
   * Download file
   */
  public downloadFile(timelineActionGuid: string, timelineActionFileGuid: string): void {
      this.downloadTimelineActionFile.emit({timelineActionGuid, timelineActionFileGuid});
  }
}
