import { Injectable } from "@angular/core";
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';

@Injectable()
export class ToastService {

  constructor(
      private translateService: TranslateService,
      private toastrService: ToastrService
  ) {}

  public success(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.toastrService.success(message ? this.translateService.instant(message) : null, title ? this.translateService.instant(title) : null, override);
  }

  public error(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.toastrService.error(message ? this.translateService.instant(message) : null, title ? this.translateService.instant(title) : null, override);
  }

  public warning(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.toastrService.warning(message ? this.translateService.instant(message) : null, title ? this.translateService.instant(title) : null, override);
  }

  public info(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    this.toastrService.info(message ? this.translateService.instant(message) : null, title ? this.translateService.instant(title) : null, override);
  }
}
