import { AuthenticationState } from './auth.model';
import {AuthenticationActions, LOGGED_IN, LOGGED_OUT, UNAUTHORIZED} from './auth.actions';

const initialState: AuthenticationState = {
    user: null,
};

export function AuthenticationReducer(
    state: AuthenticationState = initialState,
    action: AuthenticationActions
): AuthenticationState {
    switch (action.type) {
        case LOGGED_IN:
            return { user: action.payload };
        case LOGGED_OUT:
        case UNAUTHORIZED:
            return initialState;
        default:
            return state;
    }
}
