import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {MenuItem} from '../interfaces/menu.interface';

@Injectable()
export class MenuService {

    /**
     * Constructor
     */
    public constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    /**
     * Get authorized menu items
     */
    public getAuthorizedMenuItems(menuItems: MenuItem[]): MenuItem[] {
        return menuItems.filter((item: MenuItem) => {
            return this.authService.hasRole(item.role);
        });
    }
}
