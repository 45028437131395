import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'drive-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(
    public translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public ngOnInit() {
    this.setDocumentLang(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((res) => {
      this.setDocumentLang(res.lang);
    });
  }

  /**
   * Set document lang
   * @param lang
   * @private
   */
  private setDocumentLang(lang: string) {
    this.document.documentElement.lang  = lang;
  }
}
