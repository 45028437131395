import {ApiHttpClientService} from './api-http-client.service';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import {Injector} from '@angular/core';

export class SyntecTranslateLoader implements TranslateLoader {

    constructor(private injector: Injector, private scope: string, private prefix: string) {
    }

    public getTranslation(lang: string): Observable<any> {
        const apiClient = this.injector.get(ApiHttpClientService);
        return apiClient.get(`/core/translation/${lang}.json?scope=` + this.scope)
            .pipe(map((ev: object) => {
                const shortTranslations = {};

                for (const key in ev) {
                    if (ev.hasOwnProperty(key)) {
                        shortTranslations[key.replace(this.prefix, '')] = ev[key];
                    }
                }

                return shortTranslations;
            }));
    }
}
