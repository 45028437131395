import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttpClientService} from './api-http-client.service';
import {ViewParams} from '../interfaces/view/view-params.model';
import {View} from '../interfaces/view/view.model';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {FileService} from './file.service';
import {File} from '../interfaces/file.interface';
import {LoggedInAction, LoggedOutAction} from '../stores/auth/auth.actions';
import {Store} from '@ngrx/store';
import {AuthenticationState} from '../stores/auth/auth.model';
import {CoreUser} from '../interfaces/core-user.interface';
import {RequestOptions} from '../interfaces/request-options.interface';
import {Record} from '../interfaces/record.interface';
import {RecordResponse} from '../interfaces/record-response.interface';
import {EnvService} from './env.service';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from './loader.service';
import {CmsItem} from '../model/cms-item.model';
import {CmsItemAdapter} from '../adapters/cms-item.adapter';

@Injectable()
export class CoreApiService extends ApiHttpClientService {
    constructor(
        protected http: HttpClient,
        protected store: Store<{ auth: AuthenticationState }>,
        private fileService: FileService,
        protected env: EnvService,
        protected translateService: TranslateService,
        protected loaderService: LoaderService,
        private cmsItemAdapter: CmsItemAdapter
    ) {
        super(http, store, env, translateService, loaderService);
    }

    public login(username: string, password: string, options?: RequestOptions): Observable<CoreUser> {
        options = this.appendHeader(options, 'Authorization', 'Basic ' + btoa(unescape(encodeURIComponent(`${username}:${password}`))));
        options = { withCredentials: true, ...options }

        return this.getUser(options);
    }

    public getUser(options?: RequestOptions): Observable<CoreUser> {
        return this.get('/core/user', options).pipe(
            tap((user: CoreUser) => {
                this.store.dispatch(new LoggedInAction(user));
            }),
            map(x => {
                return x as CoreUser;
            })
        )
    }

    public checkPassword(password: string): Observable<any> {
        return this.post('/core/user/checkpassword', {password});
    }

    public logout(options?: RequestOptions): Observable<any> {
        return this.get('/core/auth/logout', options).pipe(
            tap(() => {
                this.store.dispatch(new LoggedOutAction());
            })
        )
    }

    public getView(viewGuid: string, params: ViewParams): Observable<View> {
        return this.post(`/core/view/${viewGuid}`, params);
    }

    public getViewXlsx(viewGuid: string, params: ViewParams): Observable<File> {
        return this.post(`/core/view/${viewGuid}/xlsx`, params, {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json'
        })
            .pipe(map((res: HttpResponse<any>) => {
                return this.fileService.getFile(res);
            }));
    }

    public updatePassword(curPassword: string, newPassword: string): Observable<object> {
        return this.post(`/core/user/updatepassword`, {
            curPassword: curPassword,
            newPassword: newPassword
        });
    }

    public checkResetPasswordToken(token: string): Observable<boolean> {
        return this.get(`/core/user/checkresetpasswordtoken/${token}`).pipe(map((y: any) => y.success));
    }

    public resetPassword(token: string, password: string): Observable<any> {
        return this.http.post<any>(`/core/user/resetpassword`, {
            token: token,
            password: password
        });
    }

    public getRecord(formGuid: string, guid?: string | null, options?: RequestOptions): Observable<Record> {
        if (guid) {
            return this.get(`/core/record/${formGuid}/${guid}`, options);
        } else {
            return this.get(`/core/record/${formGuid}`, options);
        }
    }

    public saveRecord(formGuid: string, data: any, guid?: string, options?: RequestOptions): Observable<RecordResponse> {
        if (guid) {
            return this.put(`/core/record/${formGuid}/${guid}`, data, options);
        } else {
            return this.post(`/core/record/${formGuid}`, data, options);
        }
    }

    /**
     * Get feedback types
     */
    public getFeedbackTypes(): Observable<any[]> {
        return this.get(`/tvm/feedback/types`);
    }

    /**
     * Accept platform intro by current user
     * @param guid
     * @param options
     */
    public acceptPlatformIntro(guid: string, options?: RequestOptions) {
        return this.get(`/tvm/user/accept_platform_intro/${guid}`, options);
    }

    public getCmsItem(
        slug: any,
        isNewsItem: boolean,
        isPage: boolean,
        jwtToken: string | null,
        dispatchErrors: boolean
    ): Observable<CmsItem> {
        const params: {} = {};

        if (isNewsItem) {
            params[`isNewsItem`] = `1`;
        }

        if (isPage) {
            params[`isPage`] = `1`;
        }

        if (jwtToken) {
            params[`token`] = jwtToken;
        }

        return this.get<CmsItem>(
            `/tvm/drive/cms/item/${slug}`,
            {
                params: new HttpParams({fromObject: params}),
                dispatchError: dispatchErrors
            }
        ).pipe(
            map((cmsItem: {}) => this.cmsItemAdapter.fromApi(cmsItem))
        );
    }
}
