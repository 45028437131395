<footer class="c-footer">
   <ng-content></ng-content>
    <div class="c-sub-footer">
        <div class="container">
          <ul class="sub-footer-list copy">
            <li>
              <a href="https://www.tvm.be" target="_blank">© {{currentDate | date:'yyyy'}} | TVM</a>
            </li>
          </ul>
          <ul class="sub-footer-list">
            <li *ngIf="isLoggedIn">
                <a [routerLink]="'/terms'">{{ 'global.terms_of_use' | translate }}</a>
            </li>
            <li>
                <a [href]="'global.disclaimer.url' | translate" target="_blank">{{ 'global.disclaimer' | translate }}</a>
            </li>
            <li>
              <a [href]="'global.privacy_statement.url' | translate" target="_blank">{{ 'global.privacy_statement' | translate }}</a>
            </li>
            <li>
              <a [href]="'global.cookies.url' | translate" target="_blank">{{ 'global.cookies' | translate }}</a>
            </li>
          </ul>
          <ul class="socials">
            <li class="item">
              <a target="_blank" rel="noopener" href="https://www.facebook.com/TVMBelgium/">
                <span aria-hidden="true" class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.1 19.9"><path d="M20.1 10c0-5.5-4.5-10-10.1-10C4.5 0 0 4.5 0 10c0 5 3.7 9.1 8.5 9.9v-7H5.9V10h2.6V7.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V10h2.8l-.4 2.9h-2.3v7c4.7-.8 8.4-4.9 8.4-9.9z"></path></svg>
                </span>
              </a>
            </li>
            <li class="item">
              <a target="_blank" rel="noopener" href="https://be.linkedin.com/company/tvm-belgium">
                <span aria-hidden="true" class="icon">
                  <svg class="svg--social-linkedin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M49.999 30.43V49H39.281V31.674c0-4.354-1.549-7.324-5.428-7.324-2.961 0-4.725 2.002-5.5 3.938-.283.691-.354 1.656-.354 2.625V49h-10.72s.145-29.348 0-32.389h10.719v4.592c-.021.035-.051.07-.07.104h.07v-.104c1.424-2.205 3.968-5.352 9.66-5.352 7.054.001 12.341 4.629 12.341 14.579zM6.066 1C2.399 1 0 3.416 0 6.594c0 3.109 2.33 5.6 5.924 5.6h.072c3.738 0 6.063-2.488 6.063-5.6C11.988 3.416 9.733 1 6.066 1zM.636 49h10.716V16.611H.636V49z"></path></svg>
               </span>
              </a>
            </li>
            <li class="item">
              <a target="_blank" rel="noopener" href="https://twitter.com/TVMBelgium">
                <span aria-hidden="true" class="icon">
                  <svg class="svg--social-twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
               </span>
              </a>
            </li>
          </ul>
        </div>
    </div>
</footer>
