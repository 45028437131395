import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import { saveAs } from 'file-saver';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewAction} from '../../interfaces/view/view-action.model';
import {View} from '../../interfaces/view/view.model';
import {TableBodyRow} from '../../interfaces/view/table-body-row.model';
import {ViewColumn} from '../../interfaces/view/view-column.model';
import {ViewParams} from '../../interfaces/view/view-params.model';
import {SessionStorageService} from '../../services/session-storage.service';
import {CoreApiService} from '../../services/core-api.service';
import {MessageService} from '../../services/message.service';
import {Subscription} from 'rxjs';
import {Message} from '../../model/message';

@Component({
  selector: 'core-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  @Input() public hasTableHeader = true;
  @Input() public hasExtraOptions = true;
  @Input() public hasPagination = true;
  @Input() public hasCheckboxes = true;
  @Input() public viewGuid: string;
  @Input() public showFilters = true;
  @Input() public saveViewParams = true;
  @Input() public placeholders = {};
  @Input() public viewActions: ViewAction[] = [];
  @Input() public headerTopTemplate: TemplateRef<any>;
  @Input() public headerTemplate: TemplateRef<any>;
  @Input() public footerTemplate: TemplateRef<any>;
  @Input() public callbackData: (view: View, viewComponent: ViewComponent) => void;
  @Input() public noRecordsMessage: string;
  @Input() public rowClickAction: (view: View, bodyRow: TableBodyRow) => void;

  public view: View;
  private columns: ViewColumn[];
  private simpleFilters = {}; // Key/value object containing the simple filter for each column. Could not be fixed with getter function in ngModel because of infinite loop (error in Angular)
  private resultsFirst: number;
  private resultsLast: number;
  private messageServiceSubscription: Subscription;

  constructor(
      private apiService: CoreApiService,
      private modalService: NgbModal,
      private sessionStorageService: SessionStorageService,
      private messageService: MessageService,
  ) {
    try {
      // Subscribe to message events
      this.messageServiceSubscription = this.messageService.data.subscribe((message: Message) => {
        if (
          message.action === 'view-submit' &&
          this.viewGuid === message.params
        ) {
          this.submit();
        }
      });
    } catch (ex) {
      // If no active modal is available, then just ignore the exception. The view is not opened in a modal
    }
  }

  /**
   * Submit view when opening
   */
  public ngOnInit() {
      this.submit();
  }

  /**
   * Add placeholder
   */
  public addPlaceholder(key: string, value: any) {
    this.view.params.placeholders[key] = value;
  }

  /**
   * Remove placeholder
   */
  public removePlaceholder(key: string) {
    if (this.view.params.placeholders[key] !== undefined) {
      delete this.view.params.placeholders[key];
    }
  }

  /**
   * Get default params
   */
  private getDefaultViewParams(): ViewParams {
    return {
      filters: [],
      globalSearch: '',
      limit: 25,
      page: 1,
      placeholders: this.placeholders,
      sortingColumn: null,
      sortingOrder: 'asc'
    };
  }


  /**
   * Sort by column
   */
  private sort(column, isSortable) {
    if (!isSortable) {
      return;
    }

    if (
        this.view.params.sortingColumn !== column ||
        (this.view.params.sortingColumn === column &&
            this.view.params.sortingOrder === 'desc')
    ) {
      this.view.params.sortingOrder = 'asc';
    } else {
      this.view.params.sortingOrder = 'desc';
    }

    this.view.params.sortingColumn = column;

    this.submit();
  }

  /**
   * Submit view data
   */
  public submit(event = null, resetPageNum = true) {
    if (event) {
      event.preventDefault();
    }

    let viewParams;
    if (this.view) {
      // Use params from view if available
      viewParams = this.view.params;
      if (resetPageNum) {
        viewParams.page = 1;
      }

    } else {
      // If no params available, then check if they are available in session storage
      if (this.saveViewParams) {
        viewParams = this.getViewParams();
      }
      if (!viewParams) {
        // Otherwise set default view params
        viewParams = this.getDefaultViewParams();
      }
    }

    this.apiService.getView(this.viewGuid, viewParams).subscribe((view: View) => {
      if (typeof this.callbackData === 'function') {
        this.callbackData(view, this);
      }
      this.view = view;
      this.initialiseColumns();
      this.setSimpleFilters();

      const resultsLast = view.params.page * view.params.limit;
      this.resultsFirst = ((view.params.page - 1) * view.params.limit) + 1;
      this.resultsLast = resultsLast < view.table.totalRecords ? resultsLast : view.table.totalRecords;

      if (this.saveViewParams) {
        this.sessionStorageService.set(this.getViewSessionStorageId(), this.view.params);
      }
    });
  }

  /**
   * Add simple filter
   */
  private addSimpleFilter(column, value) {
    // Add/update filter value param for columm
    const filter = this.view.params.filters.find(x => x.column === column);
    if (filter) {
      filter.value = value;
    } else {
      this.view.params.filters.push({
        column: column,
        operator: 'cn', // Seach for contains by default
        value: value
      });
    }

    this.setSimpleFilter(column, value);
  }

  /**
   * Add simple filter from select
   */
  private addSimpleFilterFromSelect(column: string, value: string|null) {
    this.simpleFilters[column] = value;
    this.addSimpleFilter(column, value);

    this.submit();
  }

  /**
   * Export data to xlsx
   */
  private exportXlsx() {
    this.apiService.getViewXlsx(this.viewGuid, this.view.params).subscribe(res => {
      saveAs(res.file, res.fileName);
    });
  }

  /**
   * Set simple filter
   */
  private setSimpleFilter(column, value) {
    this.simpleFilters[column] = value;
  }

  /**
   * Initialise columns
   */
  private initialiseColumns() {
    const columns = [];

    if (this.view.table.headerRows[0] !== undefined) {
      let key;
      let values;
      let column: ViewColumn;

      for (key in this.view.table.headerRows[0].cells) {
        if (this.view.table.headerRows[0].cells.hasOwnProperty(key)) {
          values = this.view.table.headerRows[0].cells[key];
          column = {
            guid: values.guid,
            classes: values.classes,
            key: values.key,
            name: values.value,
            isPublic: values.isVisible,
            isEditable: values.isEditable,
            isVisible: !values.defaultHidden,
            isFilter: values.isFilter,
            tabFldId: values.tabFldId,
            fldTyp: values.fldTyp,
            width: values.width
          };

          columns.push(column);
        }
      }
    }

    this.columns = columns;
  }

  /**
   * Get all checked rows
   */
  public getCheckedRows() {
    return this.view.table.bodyRows.filter(row => row.checked);
  }

  /**
   * Click row
   */
  public clickRow(view: View, bodyRow: TableBodyRow, cell: ViewColumn) {
    if (this.rowClickAction && !cell.isEditable && !bodyRow.cells[cell.key].componentValues) {
      this.rowClickAction(view, bodyRow);
    }
  }

  /**
   * Get view component
   */
  public getViewComponent() {
    return this;
  }

  /**
   * Uncheck all rows in the overview
   */
  public uncheckAll() {
    this.view.table.bodyRows.forEach(x => (x.checked = false));
  }

  /**
   * Get view params
   */
  public getViewParams(): ViewParams {
    return this.sessionStorageService.get(this.getViewSessionStorageId()) || this.getDefaultViewParams();
  }

  /**
   * Get session storage id for view
   */
  private getViewSessionStorageId() {
    return 'view-' + this.viewGuid;
  }

  /**
   * Check all rows in the overview
   */
  private checkAll(ev) {
    this.view.table.bodyRows.forEach(x => (x.checked = ev.target.checked));
  }

  /**
   * Check if all rows are checked in the overview
   */
  private isAllChecked() {
    return this.view.table.bodyRows.length > 0
        ? this.view.table.bodyRows.every(row => row.checked)
        : false;
  }

  /**
   * Set simple filters
   */
  private setSimpleFilters() {
    this.simpleFilters = [];
    for (let key in this.view.params.filters) {
      this.setSimpleFilter(
          this.view.params.filters[key]['column'],
          this.view.params.filters[key]['value']
      );
    }
  }
}
