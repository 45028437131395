/** Outer dependencies */
import { Injectable } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class DatepickerService {
  constructor() {
  }


  public getDate(year: number = null, month: number = null, day: number = null): NgbDateStruct {
    const date = new Date();

    if (year === null || year <= 0) {
      year = date.getFullYear() + (year || 0);
    }

    month = month || date.getMonth() + 1;
    day = day || date.getDate();

    return {year, month, day};
  }
}
