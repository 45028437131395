<section class="section cp">
  <div class="container">
    <div class="actions">
      <button class="btn btn-primary mb-4 btn-lg"  (click)="showAction()" *ngIf="timelineActions.length > 0">
        {{ 'timeline.timeline.new-action' | translate }}
      </button>
    </div>
  </div>
</section>

<section class="section cpt timeline timeline-full">
  <div class="container">
    <div class="c-content-panel cpt">
      <div class="empty-list-placeholder" *ngIf="!initialLoading && timelineActions.length == 0">
        <i class="icon fal fa-bolt"></i>
        <h3 class="title">{{ 'timeline.timeline.no-actions.title' | translate }}</h3>
        <div class="message">{{ 'timeline.timeline.no-actions.description' | translate }}</div>
        <button class="btn btn-primary mt-sm btn-lg"  (click)="showAction()">
          {{ 'timeline.timeline.new-action' | translate }}
        </button>
      </div>
      <div class="container cpl cpr" *ngIf="timelineActions.length > 0">
        <core-timeline-list [timelineActions]="timelineActions" [selectDefaultTimelineAction]="true" [activeTimelineActionGuid]="defaultGuidTimelineAction" (selectActionEvent)="setTimelineAction($event)"></core-timeline-list>
        <div class="main">
          <div class="inner">
            <ng-container *ngIf="activeTimelineAction">
              <h3 class="mb-md">{{ activeTimelineAction.title }}</h3>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.subject' | translate }}</label>
                <div class="col-md-8">{{ activeTimelineAction.domain }}</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.state' | translate }}</label>
                <div class="col-md-8">{{ activeTimelineAction.status }}</div>
              </div>
              <div class="form-group row" *ngIf="activeTimelineAction.user">
                <label class="col-sm-4">{{ 'timeline.inner.user' | translate }}</label>
                <div class="col-md-8">{{ activeTimelineAction.user }}</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.when' | translate }}</label>
                <div class="col-md-8">{{ (activeTimelineAction.isQuarter ? 'timeline.inner.quarter' : 'timeline.inner.exact-date') | translate }}</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.date' | translate }}</label>
                <div class="col-md-8">{{ activeTimelineAction.date | date }}</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.description' | translate }}</label>
                <div class="col-md-8" [innerHTML]="activeTimelineAction.description | nl2br"></div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{ 'timeline.inner.created_by' | translate }}</label>
                <div class="col-md-8" [innerHTML]="activeTimelineAction.createdByCoreUser.name"></div>
              </div>
              <div class="form-group row" *ngIf="activeTimelineAction.tvmPartnerCustomerTimelineActionFiles.length">
                <label class="col-sm-4">{{ 'timeline.inner.attachments' | translate }}</label>
                <div class="col-md-8">
                  <ul class="attachments">
                    <ng-container *ngFor="let actionFile of activeTimelineAction.tvmPartnerCustomerTimelineActionFiles">
                      <li class="attachment" *ngIf="actionFile.coreFile.checksum">
                        <div class="name" (click)="downloadFile(activeTimelineAction.guid, actionFile.guid)">{{actionFile.coreFile.name}}</div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="actions" *ngIf="activeTimelineAction && canEdit(activeTimelineAction)">
            <div class="btn btn-light" (click)="deleteAction(activeTimelineAction.guid)">{{ 'global.delete' | translate }}</div>
            <div class="btn btn-primary" (click)="showAction(activeTimelineAction.guid)">{{ 'global.edit' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
