<div class="breadcrumbs">
    <ul>
        <li>
            <a href [routerLink]="''">{{'breadcrumb.home' | translate}}</a>
        </li>
        <li *ngFor="let breadCrumb of breadcrumbs">
            <a href [routerLink]="breadCrumb.routerLink">{{breadCrumb.name | translate}}</a>
        </li>
        <li class="current">
            <a>{{'breadcrumb.current_page' | translate}}</a>
        </li>
    </ul>
</div>
