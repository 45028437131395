import {NgModule} from '@angular/core';
import {AlertService} from './services/alert.service';
import {AlertComponent} from './components/alert/alert.component';
import {ViewComponent} from './components/view/view.component';
import {ViewCellComponent} from './components/view/view-cell.component';
import {SessionStorageService} from './services/session-storage.service';
import {FileService} from './services/file.service';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './pipes/safeHtml.pipe';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HeaderComponent} from './components/header/header.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {FormsModule} from '@angular/forms';
import {ApiHttpClientService} from './services/api-http-client.service';
import {PasswordValidationComponent} from './components/password-validation/password-validation.component';
import {PasswordValidator} from './validators/password.validator';
import {FieldValidationComponent} from './components/field-validation/field-validation.component';
import {EnvServiceProvider} from './services/env.service.provider';
import {DatepickerService} from './services/datepicker.service';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {SafeUrlPipe} from './pipes/safeUrl.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {SplitPipe} from './pipes/split.pipe';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {HttpClientModule} from '@angular/common/http';
import {UtilService} from './services/util.service';
import {MessageService} from './services/message.service';
import {TimelineComponent} from './components/timeline/timeline.component';
import {TimelineListComponent} from "./components/timeline/timeline-list/timeline-list.component";
import {TimelineActionComponent} from "./components/timeline/timeline-action/timeline-action.component";
import {NgPipesModule} from "ngx-pipes";
import {FileUploadModule} from "ng2-file-upload";
import {LoaderService} from "./services/loader.service";
import {VimeoComponent} from './components/vimeo/vimeo.component';
import {PlatformIntroService} from './services/platform-intro.service';
import {CmsItemComponent} from './components/cms/cms-item/cms-item.component';
import {NewsItemComponent} from './components/cms/news-item/news-item.component';
import {PageComponent} from './components/cms/page/page.component';
import {CmsItemResolver} from './resolvers/cms-item.resolver';
import {CmsItemAdapter} from './adapters/cms-item.adapter';
import {InjectHTMLDirective} from "./directives/inject-html.directive";

@NgModule({
    declarations: [
        AlertComponent,
        ViewComponent,
        ViewCellComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        JoinPipe,
        SplitPipe,
        HeaderComponent,
        FooterComponent,
        PasswordValidationComponent,
        PasswordValidator,
        FieldValidationComponent,
        BreadcrumbsComponent,
        Nl2brPipe,
        TimelineComponent,
        TimelineListComponent,
        TimelineActionComponent,
        VimeoComponent,
        CmsItemComponent,
        NewsItemComponent,
        PageComponent,
        InjectHTMLDirective
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgbModule,
        RouterModule,
        FormsModule,
        HttpClientModule,
        NgPipesModule,
        FileUploadModule
    ],
    providers: [
        FileService,
        AlertService,
        SessionStorageService,
        ApiHttpClientService,
        EnvServiceProvider,
        DatepickerService,
        UtilService,
        MessageService,
        LoaderService,
        PlatformIntroService,
        CmsItemResolver,
        CmsItemAdapter
    ],
    exports: [
        AlertComponent,
        ViewComponent,
        SafeHtmlPipe,
        SafeUrlPipe,
        JoinPipe,
        SplitPipe,
        HeaderComponent,
        FooterComponent,
        PasswordValidationComponent,
        PasswordValidator,
        FieldValidationComponent,
        BreadcrumbsComponent,
        Nl2brPipe,
        TimelineComponent,
        TimelineListComponent,
        TimelineActionComponent,
        VimeoComponent
    ],
    entryComponents: [
        AlertComponent
    ],
})
export class CoreModule {
}
