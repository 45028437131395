import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {AlertComponent} from '../components/alert/alert.component';
import {Alert, Confirm, Mandatory} from '../interfaces/alert.interface';

@Injectable()
export class AlertService {
  /**
   * Constructor
   */
  constructor(private modalService: NgbModal) {}

  /**
   * Show alert
   */
  public alert(body, alert: Alert = {}) {
    const defaultOptions = {
      size: alert.size ? alert.size as 'sm'  : 'sm' // Act as if size is 'sm', otherwise only 'sm' and 'lg' are available in component
    };
    const modalRef = this.modalService.open(AlertComponent, Object.assign(defaultOptions, alert));

    modalRef.componentInstance.type = 'alert';
    modalRef.componentInstance.title = alert.title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.btnClose = alert.btnClose;

    modalRef.result.then(
        () => {
          if (alert.close) {
            alert.close();
          }
        },
        dismiss => {}
    );
  }

  /**
   * Show confirm
   */
  public confirm(body, confirm: Confirm = {}) {
    const defaultOptions = {
      size: confirm.size ? confirm.size as 'sm'  : 'sm' // Act as if size is 'sm', otherwise only 'sm' and 'lg' are available in component
    };

    const modalRef = this.modalService.open(AlertComponent, Object.assign(defaultOptions, confirm));

    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.title = confirm.title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.btnClose = confirm.btnClose;
    modalRef.componentInstance.btnCancel = confirm.btnCancel;

    modalRef.result.then(
        () => {
          if (confirm.close) {
            confirm.close();
          }
        },
        dismiss => {
          if (confirm.cancel) {
            confirm.cancel();
          }
        }
    );
  }

  /**
   * Show mandatory
   */
  public mandatory(body, mandatory: Mandatory = {}) {
    const modalRef = this.modalService.open(AlertComponent, {
      size: mandatory.size ? mandatory.size as 'sm' : 'sm', // Act as if size is 'sm', otherwise only 'sm', 'lg' and 'xl' are available in component
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.type = 'mandatory';
    modalRef.componentInstance.title = mandatory.title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.btnClose = mandatory.btnClose;

    modalRef.result.then(
      () => {
        if (mandatory.close) {
          mandatory.close();
        }
      },
    );
  }
}
