import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'core-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() public breadcrumbs: Breadcrumb[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
