<div class="image-split">
  <div class="wrapper">
    <div class="container">
      <div class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.942 24.846"><g data-name="Group 50" fill="#4fc15d" fill-rule="evenodd"><path data-name="Path 38" d="M3.953 0L0 10.212h10.671L5.087 24.846H23.2l-5.583-14.634h10.67L24.335 0z"></path><path data-name="Path 36" d="M45.872 0h9.938l-9.535 24.846h-9.941L26.834 0h9.938l4.565 11.895z"></path><path data-name="Path 37" d="M49.257 24.846h9.938l4.6-11.988 4.6 11.988h7.454l4.565-11.9 4.565 11.9h9.969L85.377 0h-8.7l-4.565 11.895L67.519 0h-8.7z"></path></g></svg>
        <div class="environment">drive</div>
      </div>
    </div>
    <div class="side">
      <img src="./assets/images/ship-boy.jpg" alt="TVM Drive">
    </div>
    <div class="container">
      <div class="authentication">
        <h1 class="title mb-md">{{'home.title' | translate}}</h1>
      </div>
      <div class="platform-links">
        <a href="{{driveLinks.platformBrokersBaseUri}}?lang={{currentLang}}" class="item broker">
        <div class="content">
          <h3 class="title">{{'home.brokers.title' | translate}}</h3>
          <div class="line">{{'home.brokers.description' | translate}}</div>
          <div class="btn btn-light btn-lg">{{'home.brokers.link' | translate}}</div>
        </div>
        <div class="image">
          <img src="./assets/images/tvm-drive-broker.jpg" alt="{{'home.brokers.title' | translate}}">
        </div>
      </a>
      <a href="{{driveLinks.platformCustomersBaseUri}}?lang={{currentLang}}" class="item customer">
        <div class="content">
          <h3 class="title">{{'home.customers.title' | translate}}</h3>
          <div class="line">{{'home.customers.description' | translate}}</div>
          <div class="btn btn-light btn-lg">{{'home.customers.link' | translate}}</div>
        </div>
        <div class="image">
          <img src="./assets/images/tvm-drive-customer.jpg" alt="{{'home.customers.title' | translate}}">
        </div>
      </a>
      <a href="https://www.tvmsolutions.be/frontend/web/site/login" class="item solutions">
        <div class="content">
          <h3 class="title">{{'home.solutions.title' | translate}}</h3>
          <div class="line">{{'home.solutions.description' | translate}}</div>
          <div class="btn btn-light btn-lg">{{'home.solutions.link' | translate}}</div>
        </div>
        <div class="image">
          <img src="./assets/images/tvm-solutions.jpg" alt="{{'home.solutions.title' | translate}}">
        </div>
      </a>
      </div>
    </div>
  </div>
</div>
