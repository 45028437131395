<ng-container *ngIf="view">
    <ng-container *ngIf="headerTopTemplate" [ngTemplateOutlet]="headerTopTemplate" [ngTemplateOutletContext]="{viewComponent: this}"></ng-container>

    <div>
        <div class="view-header mb-sm" *ngIf="hasTableHeader || headerTemplate">
            <div class="input-group search" *ngIf="hasTableHeader">
              <input type="search" class="form-control" [(ngModel)]="view.params.globalSearch" placeholder="{{ 'view.header.global_search' | translate }}" (keypress)="$event.keyCode == 13 ? submit($event) : null" (search)="submit()">
              <div class="input-group-append">
                <button type="submit" class="btn btn-secondary" (click)="submit()"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <div ngbDropdown *ngIf="hasTableHeader && hasExtraOptions">
              <button class="btn btn-secondary" ngbDropdownToggle>{{ 'view.header.extra_options' | translate }}</button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="exportXlsx()"><i class="fal fa-file-excel fa-fw"></i>{{ 'view.header.extra_options.download_excel' | translate }}</button>
                <button ngbDropdownItem (click)="showFilters = !showFilters"><i class="fal fa-search fa-fw"></i>
                  <ng-container *ngIf="!showFilters">{{ 'view.header.extra_options.show_filters' | translate }}</ng-container>
                  <ng-container *ngIf="showFilters">{{ 'view.header.extra_options.hide_filters' | translate }}</ng-container>
                </button>
                <div class="dropdown-divider" *ngIf="viewActions.length"></div>
                <ng-container *ngFor="let action of viewActions">
                  <button ngbDropdownItem (click)="action.function(getViewComponent())"><i class="fal {{action.icon}} fa-fw"></i>{{ action.name | translate }}</button>
                </ng-container>
              </div>
            </div>
          <ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{viewComponent: this}"></ng-container>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover cmb table-fixed" [ngClass]="{'table-hover': rowClickAction}">
                <thead>
                <tr *ngFor="let headerRow of view.table.headerRows">
                    <th style="width: 38px; text-overflow: initial;" *ngIf="hasCheckboxes" class="text-center">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="checkbox-all" [checked]="isAllChecked()" (change)="checkAll($event)">
                          <label class="custom-control-label" for="checkbox-all"></label>
                        </div>
                    </th>
                    <ng-container *ngFor="let cell of columns;">
                        <ng-container *ngIf="headerRow.cells[cell.key] !== undefined">
                            <th class="{{headerRow.cells[cell.key].classes.join(' ')}}" [ngClass]="{'sortable': headerRow.cells[cell.key].isSortable, 'active': cell.key == view.params.sortingColumn, 'desc': cell.key == view.params.sortingColumn && view.params.sortingOrder == 'desc', 'asc': cell.key == view.params.sortingColumn && view.params.sortingOrder == 'asc'}" (click)="sort(cell.key, headerRow.cells[cell.key].isSortable)" [ngStyle]="{'width': (cell.width > 0 ? cell.width : '')  + 'px'}" *ngIf="cell.isPublic && cell.isVisible">
                                {{headerRow.cells[cell.key].value}}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                <ng-container *ngIf="showFilters">
                  <tr class="table-row-filters" *ngFor="let headerRow of view.table.headerRows">
                    <th *ngIf="hasCheckboxes"></th>
                    <ng-container *ngFor="let cell of columns;">
                      <ng-container *ngIf="headerRow.cells[cell.key] !== undefined">
                        <th *ngIf="cell.isPublic && cell.isVisible" class="text-center" [ngClass]="{'active': cell.isFilter, 'has-search': (cell.isFilter && !headerRow.cells[cell.key].selectValues)}">
                          <ng-container *ngIf="cell.isFilter">
                            <ng-container *ngIf="!headerRow.cells[cell.key].selectValues">
                              <div class="search-container">
                                <input type="text" class="form-control" [ngModel]="simpleFilters[cell.key]" (ngModelChange)="addSimpleFilter(cell.key, $event)" [disabled]="!headerRow.cells[cell.key].isFilter" (keypress)="$event.keyCode == 13 ? submit($event) : null" (search)="submit()">
                                <ng-container *ngIf="simpleFilters[cell.key]">
                                  <button type="button" (click)="addSimpleFilterFromSelect(cell.key, null)" class="btn-clear">
                                    <i class="fal fa-times"></i>
                                  </button>
                                </ng-container>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="headerRow.cells[cell.key].selectValues">
                              <div ngbDropdown container="body">
                                <button type="button" class="btn btn-sm" ngbDropdownToggle [class.placeholder-shown]="!simpleFilters[cell.key]">
                                  <div class="text">{{simpleFilters[cell.key] || ('view.header.select_option' | translate)}}</div>
                                </button>
                                <ng-container *ngIf="simpleFilters[cell.key]">
                                  <button type="button" (click)="addSimpleFilterFromSelect(cell.key, null)" class="btn-clear">
                                    <i class="fal fa-times"></i>
                                  </button>
                                </ng-container>
                                <div ngbDropdownMenu>
                                  <ng-container *ngFor="let value of headerRow.cells[cell.key].selectValues">
                                    <button ngbDropdownItem (click)="addSimpleFilterFromSelect(cell.key, value)">{{value}}</button>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </th>
                      </ng-container>
                    </ng-container>
                  </tr>
                </ng-container>
                </thead>
                <tbody>
                <tr *ngFor="let bodyRow of view.table.bodyRows; let i = index;" [ngClass]="bodyRow.classes.join(' ')">
                    <td *ngIf="hasCheckboxes" class="text-center">
                       <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" id="checkbox-{{view.viewGuid}}-{{i}}" [(ngModel)]="bodyRow.checked">
                         <label class="custom-control-label" for="checkbox-{{view.viewGuid}}-{{i}}"></label>
                       </div>
                    </td>
                    <ng-container *ngFor="let cell of columns;">
                        <ng-container *ngIf="bodyRow.cells[cell.key] !== undefined">
                            <td *ngIf="cell.isPublic && cell.isVisible" class="{{bodyRow.cells[cell.key].classes.join(' ')}}" style="white-space: normal;" [ngClass]="{'cursor-pointer': rowClickAction}" (click)="clickRow(view, bodyRow, cell)">
                                <ng-container *ngIf="bodyRow.cells[cell.key].component">
                                    <view-cell-component [component]="bodyRow.cells[cell.key].component" [row]="bodyRow" [cell]="bodyRow.cells[cell.key]" [viewComponent]="this" [values]="bodyRow.cells[cell.key].componentValues"></view-cell-component>
                                </ng-container>
                                <ng-container *ngIf="!bodyRow.cells[cell.key].component">
                                    <ng-container *ngIf="cell.isEditable" [ngSwitch]="cell.fldTyp">
                                        <input type="text" class="form-control form-control-sm" *ngSwitchCase="'text'" [(ngModel)]="bodyRow.cells[cell.key].value" (keyup)="bodyRow.cells[cell.key].onChange(bodyRow, bodyRow.cells[cell.key])" [disabled]="bodyRow.cells[cell.key].disabled">
                                        <input type="number" class="form-control form-control-sm" step="1" min="0" *ngSwitchCase="'number'" [(ngModel)]="bodyRow.cells[cell.key].value" (change)="bodyRow.cells[cell.key].onChange(bodyRow, bodyRow.cells[cell.key], this)" [disabled]="bodyRow.cells[cell.key].disabled">

                                        <select class="form-control form-control-sm" *ngSwitchCase="'select'" [(ngModel)]="bodyRow.cells[cell.key].value" (change)="bodyRow.cells[cell.key].onChange(bodyRow, bodyRow.cells[cell.key])" [disabled]="bodyRow.cells[cell.key].disabled">
                                            <option *ngFor="let option of bodyRow.cells[cell.key].options" [value]="option.value">{{option.name}}</option>
                                        </select>
                                    </ng-container>
                                    <ng-container *ngIf="!cell.isEditable">
                                        <div [ngSwitch]="cell.fldTyp">
                                            <ng-container *ngSwitchCase="'checkbox'">
                                                <i class="fas fa-check text-success" *ngIf="bodyRow.cells[cell.key].value == '1'"></i>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'html'">
                                                <div [innerHTML]="bodyRow.cells[cell.key].value | safeHtml"></div>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>{{bodyRow.cells[cell.key].value}}</ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="empty-list-placeholder" *ngIf="!view.table.totalRecords">
            <i class="icon fal fa-search"></i>
            <div class="message" [innerHTML]="(noRecordsMessage || 'view.no_results') | translate | safeHtml"></div>
        </div>

        <div class="view-footer" *ngIf="footerTemplate">
            <ng-container [ngTemplateOutlet]="footerTemplate" [ngTemplateOutletContext]="{viewComponent: this}"></ng-container>
        </div>
        <div class="view-footer mt-sm" *ngIf="hasPagination && view.table.totalRecords > 0">
            <div class="d-flex align-items-center">
                <ngb-pagination
                        [(page)]="view.params.page"
                        [pageSize]="view.params.limit"
                        [collectionSize]="view.table.totalRecords"
                        [maxSize]="2"
                        [rotate]="true"
                        (pageChange)="submit(null, false)"
                        [boundaryLinks]="true"
                        *ngIf="view.table.totalRecords > view.params.limit">
                        <ng-template ngbPaginationFirst><i class="fal fa-angle-double-left"></i></ng-template>
                        <ng-template ngbPaginationLast><i class="fal fa-angle-double-right"></i></ng-template>
                        <ng-template ngbPaginationPrevious><i class="fal fa-angle-left"></i></ng-template>
                        <ng-template ngbPaginationNext><i class="fal fa-angle-right"></i></ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
            <div class="d-flex justify-content-center align-items-center text-center">{{ 'view.pagination.results' | translate:{first: resultsFirst, last: resultsLast, total: view.table.totalRecords} }}</div>
            <div class="d-flex justify-content-end align-items-center">
                <select class="form-control number-of-results mr-sm" [(ngModel)]="view.params.limit" (change)="submit()">
                    <option *ngFor="let value of view.limits" [value]="value">{{value}}</option>
                </select>
                <span>{{ 'view.pagination.results_show' | translate }}</span>
            </div>
        </div>
    </div>
</ng-container>
