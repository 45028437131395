import {Injectable} from "@angular/core";
import {AlertService} from './alert.service';
import {PlatformIntro} from '../model/platform-intro.model';
import {Alert, Confirm} from '../interfaces/alert.interface';
import {CoreApiService} from './core-api.service';

@Injectable()
export class PlatformIntroService {

    constructor(private alertService: AlertService, private coreApiService: CoreApiService) {
    }

    /**
     * Display intro information in modal
     */
    public displayModal(platformIntro: PlatformIntro|null): void {
        if (!platformIntro) {
            return;
        }

        const modalSettings: Confirm | Alert = {
            btnClose: platformIntro.confirmButton,
            title: platformIntro.title,
            size: platformIntro.size,
            backdrop: 'static',
            keyboard: false,
            close: () => {
                this.coreApiService.acceptPlatformIntro(platformIntro.guid).subscribe();
            }
        };

        if (platformIntro.hasCancelButton) {
            modalSettings['btnCancel'] = platformIntro.cancelButton;
            this.alertService.confirm(platformIntro.body, modalSettings);
        } else {
            this.alertService.alert(platformIntro.body, modalSettings);
        }
    }
}
