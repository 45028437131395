import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {CoreApiService} from '../../../../../core/src/lib/services/core-api.service';
import {DriveLinks} from '../interfaces/drive-links.interface';

@Injectable()
export class ApiService {
  constructor(
    public coreApiService: CoreApiService
  ) {
  }

  public getDriveLinks(): Observable<DriveLinks> {
    return this.coreApiService.get<DriveLinks>(`/tvm/drive/links`);
  }
}
